import React from "react";
import {
  ProjectsWrapper,
  Slider,
  ProjectsContactWrapper,
} from "@Styles/projects";
import swipe from "@Images/pictos/swipe.png";
import { Theme } from "@Styles/Theme";
import { useStaticQuery, graphql } from "gatsby";
import ReactDOM from "react-dom";
import GalleryImage from "@Components/GalleryImage";
import Contact from "@Components/Contact";
import { throttle } from "lodash";
import { CONF } from "../conf";
import { mapDataImages, retrieveImageFluid } from "../utils";
import ProjectsMouse from "@Components/ProjectsMouse";

//***************************************************************************************************//
//***************************************************************************************************//
//******************************************** COMPONENT ********************************************//
//***************************************************************************************************//
//***************************************************************************************************//

const LowerZone = ({
  isMobile,
  setMenuIsWhite,
  displayProjectsByPosition = false,
}) => {
  ///////////////////////////////////////////////////// LOAD DATA /////////////////////////////////////////////////////
  let projects = useStaticQuery(graphql`
    query {
      allContentfulProject(filter: { node_locale: { eq: "en-US" } }) {
        edges {
          node {
            slug
            title
            thumbnail {
              fixed {
                src
                aspectRatio
                srcSet
                height
                width
              }
            }
            link
            skills
            position
          }
        }
      }
    }
  `);

  ///////////////////////////////////////////////////// CONSTS /////////////////////////////////////////////////////
  const projectsContainerRef = React.useRef();
  const triggerProjectsRef = React.useRef();
  const parentWrapperRef = React.useRef();
  const sliderRef = React.useRef();
  const isDownRef = React.useRef(false);
  const startXRef = React.useRef();
  const scrollLeftRef = React.useRef();

  const [startTypingText, setStartTypingText] = React.useState(false);

  ///////////////////////////////////////////////////// VARIABLES //////////////////////////////////////////////////
  let html;
  let topRight = 0;
  let bottomRight = -15;
  let circleSize = 125;
  let projectsObserver;

  ///////////////////////////////////////////////////// INIT /////////////////////////////////////////////////////
  React.useEffect(() => {
    html = document.querySelector("html");
    const slider = projectsContainerRef.current;

    slider.addEventListener("mousedown", (e) => {
      isDownRef.current = true;
      startXRef.current = e.pageX - slider.offsetLeft;
      scrollLeftRef.current = slider.scrollLeft;
    });
    slider.addEventListener("mouseleave", () => {
      isDownRef.current = false;
    });
    slider.addEventListener("mouseup", () => {
      isDownRef.current = false;
    });
    slider.addEventListener("mousemove", (e) => {
      if (!isDownRef.current) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startXRef.current) * 2;
      slider.scrollLeft = scrollLeftRef.current - walk;
    });

    if (!isMobile && window.innerWidth > 1024) {
      projectsObserver = new IntersectionObserver(
        projectsObserverCallback,
        projectsObserverOptions
      );
      projectsObserver.observe(triggerProjectsRef.current);
    }

    return () => {
      html.style.overflowY = "scroll";
      window.removeEventListener("wheel", scrollClipPath);
      window.removeEventListener("wheel", horizontalScroll);
      window.removeEventListener("wheel", scrollContact);
    };
  }, []);

  React.useEffect(() => {
    constructGrid();
  }, [isMobile]);

  ///////////////////////////////////////////////////// FUNCS /////////////////////////////////////////////////////
  const projectsObserverOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  };
  const projectsObserverCallback = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        html.style.overflowY = "hidden";
        window.addEventListener("wheel", scrollClipPath, {
          once: true,
          passive: true,
        });
      }
    });
  };

  const scrollClipPath = throttle((e) => {
    if (e.deltaY > 0) {
      if (topRight < 130) {
        topRight += e.deltaY > 80 ? 10 : e.deltaY / 10;
        bottomRight += e.deltaY > 80 ? 10 : e.deltaY / 10;
        parentWrapperRef.current.style.clipPath = `polygon(0 0, ${topRight}% 0%, ${bottomRight}% 100%, 0 100% )`;
      } else if (topRight >= 130) {
        setMenuIsWhite(true);
        window.addEventListener("wheel", horizontalScroll, {
          once: true,
          passive: true,
        });
        return;
      }
    } else {
      if (topRight > 0) {
        topRight += e.deltaY < -80 ? -10 : e.deltaY / 10;
        bottomRight += e.deltaY < -80 ? -10 : e.deltaY / 10;
        parentWrapperRef.current.style.clipPath = `polygon(0 0, ${topRight}% 0%, ${bottomRight}% 100%, 0 100% )`;
      } else if (topRight <= 0) {
        setTimeout(() => {
          setMenuIsWhite(false);
          html.style.overflowY = "scroll";
        }, 250);
        return;
      }
    }
    window.addEventListener("wheel", scrollClipPath, {
      once: true,
      passive: true,
    });
  }, 25);

  const horizontalScroll = throttle((e) => {
    projectsContainerRef.current.scrollLeft += e.deltaY;
    if (projectsContainerRef.current.scrollLeft <= 0 && e.deltaY < 0) {
      return window.addEventListener("wheel", scrollClipPath, {
        once: true,
        passive: true,
      });
    }
    if (
      Math.ceil(projectsContainerRef.current.scrollLeft) >=
      projectsContainerRef.current.scrollWidth - window.innerWidth
    ) {
      return window.addEventListener("wheel", scrollContact, {
        once: true,
        passive: true,
      });
    }
    window.addEventListener("wheel", horizontalScroll, {
      once: true,
      passive: true,
    });
  }, 25);

  const scrollContact = throttle((e) => {
    if (e.deltaY > 0) {
      if (circleSize > 0) {
        circleSize -= e.deltaY > 80 ? 10 : e.deltaY / 10;
        projectsContainerRef.current.style.webkitMaskSize = `${circleSize}%`;
        projectsContainerRef.current.style.zIndex = 4000;
      } else if (circleSize <= 20) {
        projectsContainerRef.current.style.webkitMaskSize = `0%`;
        projectsContainerRef.current.style.zIndex = 2000;
        setStartTypingText(true);
      }
    } else if (e.deltaY < 0) {
      setStartTypingText(false);
      projectsContainerRef.current.style.zIndex = 4000;
      circleSize -= e.deltaY < -80 ? -10 : e.deltaY / 10;
      projectsContainerRef.current.style.webkitMaskSize = `${circleSize}%`;
      if (circleSize >= 150) {
        setStartTypingText(false);
        return window.addEventListener("wheel", horizontalScroll, {
          once: true,
          passive: true,
        });
      }
    }
    window.addEventListener("wheel", scrollContact, {
      once: true,
      passive: true,
    });
  }, 25);

  const constructGrid = () => {
    const imagesArray = [];
    let p = [...projects.allContentfulProject.edges];
    if (displayProjectsByPosition) {
      p = [...p.sort((a, b) => a.node.position - b.node.position)];
    }
    p.forEach((item) => {
      const image = item.node.thumbnail.fixed;
      if (item.node.title != "Sample") {
        imagesArray.push(
          React.createElement(GalleryImage, {
            fluid: image,
            elemX: image.width,
            elemY: image.height,
            args: item.node.skills ? item.node.skills : [],
            title: item.node.title,
            slug: item.node.slug,
            hasProjectPage: item.node.link,
          })
        );
      }
    });

    const spaceDiv = React.createElement("div", {
      style: { width: "80px", height: "100%" },
    });
    imagesArray.push(spaceDiv);
    ReactDOM.render(imagesArray, sliderRef.current);
  };

  ///////////////////////////////////////////////////// RENDERING /////////////////////////////////////////////////////
  return (
    <>
      <ProjectsContactWrapper ref={parentWrapperRef} isMobile={isMobile}>
        <ProjectsWrapper
          className="projectsWrapper"
          ref={projectsContainerRef}
          style={{
            cursor: `url(${swipe}), auto`,
          }}
          isMobile={isMobile}
        >
          <Slider ref={sliderRef} />
          {isMobile && <ProjectsMouse />}
        </ProjectsWrapper>
        {!isMobile && (
          <Contact
            isMobile={isMobile}
            style={{ top: 0, right: 0, bottom: 0, left: 0 }}
            startTypingText={startTypingText}
          />
        )}
      </ProjectsContactWrapper>
      {isMobile && (
        <Contact isMobile={isMobile} startTypingText={startTypingText} />
      )}
      <div
        id="triggerProjects"
        ref={triggerProjectsRef}
        style={{
          width: "100%",
          height: "1px",
          backgroundColor: `${Theme.colors.red}`,
        }}
      />
    </>
  );
};

export default LowerZone;
