import React, { useEffect } from 'react'
import {
  SplashScreenWrapper,
  Halo,
  Flore,
  BangOne,
  BangTwo,
  BangContainer,
  Sam,
  BlueBrushOne,
  BlueBrushTwo,
  BlueBuildings,
  BluePalmOne,
  BluePalmTwo,
  BlueOwl,
  RedBrushOne,
  RedFlowerOne,
  RedFlowerTwo,
  RedPalmLeaf,
  RedPaperOne,
  RedPaperTwo,
  Lasers,
  GameOver,
  DeskTopView,
  MobileView,
  FloreBox,
  FloreMobile,
  RedPalmLeafMobile,
  RedBrushOneMobile,
  RedPaperOneMobile,
  RedFlowerOneMobile,
  SamBox,
  SamMobile,
  BluePalmOneMobile,
  BluePalmTwoMobile,
  BlueBrushOneMobile,
  BlueBrushTwoMobile,
  BlueBuildingsMobile,
  BlueOwlMobile
} from "@Styles/splash"

import blueBrushOne from "@Images/splashScreen/blue_brush_1.png"

import { useSpring, animated } from 'react-spring'
import { simpleFlickering, mapDataImages, retrieveImageFluid } from "../utils"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Splash = ({ isMobile }, ref) => {

  let dataImages = useStaticQuery(graphql`
    query SplashscreenImages {
      allFile(filter: {relativeDirectory: {eq: "splashScreen"}}) {
        edges {
          node {
            childImageSharp {
              fluid{
                base64
                originalImg
                aspectRatio
                sizes
                src
                srcSet
                originalName
              }
            }
          }
        }
      }
    }
  `
  )

  dataImages = mapDataImages(dataImages)

  const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
  const trans1 = (x, y) => `translate3d(${x / 100}px,${y / 100}px,0)`
  const trans3 = (x, y) => `translate3d(${x / 60}px,${y / 60}px,0)`
  const trans4 = (x, y) => `translate3d(${x / 40}px,${y / 40}px,0)`
  const trans5 = (x, y) => `translate3d(${x / 20}px,${y / 20}px,0)`

  const [props, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 10, tension: 550, friction: 140 } }))

  useEffect(() => {
    if (!isMobile) {
      simpleFlickering({
        elements: [
          document.getElementsByClassName('splashScreenLasers')[0]
        ],
        delta: 20,
        ratio: 0.2
      })
      simpleFlickering({
        elements: [
          document.getElementsByClassName('bang')[0],
          document.getElementsByClassName('gameOver')[0]
        ],
        delta: 20,
        ratio: 0.2
      })
    }

  }, []);

  return (
    <SplashScreenWrapper id="splashWrapper" ref={ref} isMobile={isMobile}>
      {isMobile ?
        /* ::::::::::::::::::::: MOBILE VIEW ::::::::::::::::::::: */
        <MobileView>
          <FloreBox>
            <Img
              fluid={retrieveImageFluid(dataImages, 'flore_v3.png')}
              width={"100%"}
              style={{ ...FloreMobile }}
              alt="Flore image"
            />
            <Img
              fluid={retrieveImageFluid(dataImages, 'red_palm_leaf.png')}
              width={"100%"}
              style={{ ...RedPalmLeafMobile }}
              alt="Loony feuille de palmier rouge image"
            />
            <Img
              fluid={retrieveImageFluid(dataImages, 'red_brush_1.png')}
              width={"100%"}
              style={{ ...RedBrushOneMobile }}
              alt="Loony brush rouge"
            />
            <Img
              fluid={retrieveImageFluid(dataImages, 'red_paper_1.png')}
              width={"100%"}
              style={{ ...RedPaperOneMobile }}
              alt="Loony brush rouge"
            />
            <Img
              fluid={retrieveImageFluid(dataImages, 'red_flower_1.png')}
              width={"100%"}
              style={{ ...RedFlowerOneMobile }}
              alt="Loony fleur rouge image"
            />
          </FloreBox>
          <SamBox>
            <Img
              fluid={retrieveImageFluid(dataImages, 'sam_v3.png')}
              width={"100%"}
              style={{ ...SamMobile }}
              alt="Sam Loony image"
            />
            <Img
              fluid={retrieveImageFluid(dataImages, 'blue_palm_1.png')}
              width={"100%"}
              style={{ ...BluePalmOneMobile }}
              alt="Loony palmier bleu image"
            />
            <Img
              fluid={retrieveImageFluid(dataImages, 'blue_palm_2.png')}
              width={"100%"}
              style={{ ...BluePalmTwoMobile }}
              alt="Loony palmier bleu deux image"
            />
            <Img
              fluid={retrieveImageFluid(dataImages, 'blue_brush_1.png')}
              width={"100%"}
              style={{ ...BlueBrushOneMobile }}
              alt="Loony brush bleu un"
            />
            <Img
              fluid={retrieveImageFluid(dataImages, 'blue_brush_2.png')}
              width={"100%"}
              style={{ ...BlueBrushTwoMobile }}
              alt="Loony brush bleu deux"
            />
            <Img
              fluid={retrieveImageFluid(dataImages, 'blue_buildings.png')}
              width={"100%"}
              style={{ ...BlueBuildingsMobile }}
              alt="Loony buildings bleus"
            />
            <Img
              fluid={retrieveImageFluid(dataImages, 'blue_owl.png')}
              width={"100%"}
              style={{ ...BlueOwlMobile }}
              alt="Loony hiboux bleu"
            />
          </SamBox>
        </MobileView>
        :
        /* ::::::::::::::::::::: DESKTOP VIEW ::::::::::::::::::::: */
        <DeskTopView onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
          {/* ::::::::::::::::::::: FOREGROUND ::::::::::::::::::::: */}
          <Img
            fluid={retrieveImageFluid(dataImages, 'flore_v3.png')}
            width={"100%"}
            style={{ ...Flore }}
            alt="Flore image"
            id="floreId"
          />

          <Img
            fluid={retrieveImageFluid(dataImages, 'sam_v3.png')}
            style={{ ...Sam }}
            alt="Sam image"
          />

          <Img
            fluid={retrieveImageFluid(dataImages, 'lasers.png')}
            style={{ ...Lasers }}
            alt="Lasers image"
            className="splashScreenLasers"
          />

          <Img
            fluid={retrieveImageFluid(dataImages, 'game_over.png')}
            style={{ ...GameOver }}
            alt="Game Over image"
            className="gameOver"
          />

          <BangContainer className="bang">
            <Img
              fluid={retrieveImageFluid(dataImages, 'bang.png')}
              style={{ ...BangOne }}
              alt="Bang image"
            />
            <Img
              fluid={retrieveImageFluid(dataImages, 'bang.png')}
              style={{ ...BangTwo }}
              alt="Bang image"
            />
          </BangContainer>
          {/* ::::::::::::::::::::: BACKGROUND ::::::::::::::::::::: */}
          <animated.div style={{ ...BlueBrushOne, transform: props.xy.interpolate(trans1) }}>
            <img src={blueBrushOne} width={"100%"} alt="" />
          </animated.div>

          <animated.div style={{ ...BlueBrushTwo, transform: props.xy.interpolate(trans3) }}>
            <Img
              fluid={retrieveImageFluid(dataImages, 'blue_brush_2.png')}
              width={"100%"}
              alt="Loony brush bleu deux"
            />
          </animated.div>

          <animated.div style={{ ...BlueBuildings, transform: props.xy.interpolate(trans4) }}>
            <Img
              fluid={retrieveImageFluid(dataImages, 'blue_buildings.png')}
              width={"100%"}
              alt="Loony buildings bleus"
            />
          </animated.div>

          <animated.div style={{ ...BlueOwl, transform: props.xy.interpolate(trans5) }}>
            <Img
              fluid={retrieveImageFluid(dataImages, 'blue_owl.png')}
              width={"100%"}
              alt="Loony hiboux bleu"
            />
          </animated.div>

          <Img
            fluid={retrieveImageFluid(dataImages, 'blue_palm_1.png')}
            style={{ ...BluePalmOne }}
            alt="Loony palmier bleu un"
          />

          <Img
            fluid={retrieveImageFluid(dataImages, 'blue_palm_2.png')}
            style={{ ...BluePalmTwo }}
            alt="Loony palmier bleu deux"
          />

          <animated.div style={{ ...RedBrushOne, transform: props.xy.interpolate(trans1) }}>
            <Img
              fluid={retrieveImageFluid(dataImages, 'red_brush_1.png')}
              width={"100%"}
              alt="Loony brush rouge"
            />
          </animated.div>

          <animated.div style={{ ...RedFlowerOne, transform: props.xy.interpolate(trans1) }}>
            <Img
              fluid={retrieveImageFluid(dataImages, 'red_flower_1.png')}
              width={"100%"}
              alt="Loony fleur rouge un"
            />
          </animated.div>

          <animated.div style={{ ...RedFlowerTwo, transform: props.xy.interpolate(trans1) }}>
            <Img
              fluid={retrieveImageFluid(dataImages, 'red_flower_2.png')}
              width={"100%"}
              alt="Loony fleur rouge deux"
            />
          </animated.div>

          <animated.div style={{ ...RedPalmLeaf, transform: props.xy.interpolate(trans1) }}>
            <Img
              fluid={retrieveImageFluid(dataImages, 'red_palm_leaf.png')}
              width={"100%"}
              alt="Loony feuille de palmier rouge"
            />
          </animated.div>

          <animated.div style={{ ...RedPaperOne, transform: props.xy.interpolate(trans3) }}>
            <Img
              fluid={retrieveImageFluid(dataImages, 'red_paper_1.png')}
              width={"100%"}
              alt="Loony feuille de papier déchirée rouge un"
            />
          </animated.div>

          <animated.div style={{ ...RedPaperTwo, transform: props.xy.interpolate(trans1) }}>
            <Img
              fluid={retrieveImageFluid(dataImages, 'red_paper_2.png')}
              width={"100%"}
              alt="Loony feuille de papier déchirée rouge deux"
            />
          </animated.div>

        </DeskTopView>
      }
      {!isMobile && <Halo />}
    </SplashScreenWrapper >
  )
}

const forwardedSplash = React.forwardRef(Splash)
export default forwardedSplash