import React from "react"
import styled from "styled-components"
import { Theme } from "@Styles/Theme"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

const MouseWrapper = styled.div`
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 9000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p{
    font-size: 1.6rem;
    color: #fff;
    font-weight: 900;
    font-style: italic;
  }
`

const Mouse = styled.div`
    width: 34px;
    height: 50px;
    border-radius: 21px;
    border: solid 4px #fff;
    display: flex;
    justify-content: center;
    padding: 7px;
    opacity: 1;
    margin-bottom: 10px;
`

const Line = styled.div`
    height: 10px;
    width: 3px;
    background-color: #fff;
    border-radius: 2px;
    opacity: 0.6;
    animation: scroll infinite 2s ease-in;
    @keyframes scroll {
        0%  { transform: translateY(0); opacity: 1}
        25% { transform: translateY(0); opacity: 1}
        80%, 100% { transform: translateY(7px); opacity: 0}
    }
`

export default function ProjectsMouse({
  isMobile
}) {
  return (
    !isMobile &&
    <MouseWrapper>
      <Mouse>
        <Line />
      </Mouse>
      <FontAwesomeIcon icon={faArrowRight} style={{ color: "#fff", fontSize: 25 }} />
    </MouseWrapper>
  )
}