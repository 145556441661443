import React, { lazy } from "react";
import Splash from "@Components/Splash";
import Skills from "@Components/Skills";
import Baseline from "@Components/Baseline";
import LowerZone from "@Components/LowerZone";
import { Theme } from "@Styles/Theme";
import MousePicto from "@Components/MousePicto";
import {
  EyesStyle,
  EyesBox,
  Logo,
  SectionWrapper,
  SkillsScreenWrapper,
} from "@Styles/index";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import Layout from "@Components/Layout";
import LogoLoony from "@Images/logos/logo_loony_bleu.png";
import SEO from "@Components/SEO";
import { useStaticQuery, graphql } from "gatsby";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

const IndexPage = () => {
  let data = useStaticQuery(graphql`
    query configurationAndIndexEyes {
      eyes: file(relativePath: { eq: "splashScreen/eyes.png" }) {
        childImageSharp {
          fluid(maxWidth: 1400, quality: 100) {
            aspectRatio
            base64
            originalName
            sizes
            src
          }
        }
      }
      config: contentfulConfig(configuration: { eq: "Site configuration" }) {
        configuration
        displayProjectsByPosition
        metadataKeywords
        studioCreatif
        event
        digital
        branding
        baseline {
          baseline
        }
        subBaseline {
          subBaseline
        }
      }
    }
  `);

  // CONSTS
  const skillsRef = React.useRef(null);
  const splashRef = React.useRef(null);
  const eyesRef = React.useRef(null);
  const contactTriggerRef = React.useRef(null);
  const eyesBoxRef = React.useRef(null);
  const skillsWrapperRef = React.useRef(null);
  const baselineRef = React.useRef(null);

  const [isMobile, setIsMobile] = React.useState(null);
  const [menuIsVisible, setMenuIsVisible] = React.useState(false);
  const [menuIsWhite, setMenuIsWhite] = React.useState(false);

  const initScrollAnimations = React.useCallback(() => {
    const skillsId = document.getElementById("skillsId");
    const navMenuWrapper = document.getElementById("navMenuWrapper");
    const eyes = document.getElementById("eyesZoom");
    navMenuWrapper.classList.remove("menuVisible");

    let html = document.querySelector("html");
    html.style.overflowY = "scroll";

    const tmpIsMobile = window.innerWidth < 1024;

    gsap.to(splashRef.current, {
      id: "anim1",
      scrollTrigger: {
        trigger: eyesRef.current,
        start: "top bottom",
        scrub: true,
        end: `+=${1250}`,
      },
      translateY: "-80vh",
    });

    gsap.to(eyes, {
      id: "anim2",
      scrollTrigger: {
        trigger: skillsWrapperRef.current,
        start: "top top",
        pin: tmpIsMobile ? false : true,
        scrub: true,
        end: tmpIsMobile ? `+=${window.innerHeight}` : `+=${2500}`,
      },
      scale: tmpIsMobile ? 4 : 10,
      opacity: 0,
    });

    if (!tmpIsMobile) {
      gsap.to(skillsRef.current, {
        id: "anim3",
        scrollTrigger: {
          trigger: skillsWrapperRef.current,
          start: "top top",
          pin: true,
          scrub: true,
          end: `+=${2500}`,
          onLeave: () => {
            skillsId.classList.add("visible");
            skillsId.classList.remove("noCursor");
            setMenuIsVisible(true);
            navMenuWrapper.classList.add("menuVisible");
          },
          onLeaveBack: () => {
            navMenuWrapper.classList.remove("menuVisible");
            setTimeout(() => setMenuIsVisible(false), 550);
          },
          onEnterBack: () => {
            skillsId.classList.remove("visible");
            skillsId.classList.add("noCursor");
          },
        },
        opacity: 1,
        webkitMaskSize: "400vw",
      });
    }

    setIsMobile(tmpIsMobile);
  }, []);

  React.useEffect(() => {
    initScrollAnimations();
    return () => {
      gsap.getById("anim1").kill();
      gsap.getById("anim2").kill();
      const anim3 = gsap.getById("anim3");
      if (anim3) {
        anim3.kill();
      }
    };
  }, []);

  return (
    <div>
      <Layout
        menuOpen={true}
        menuVisible={menuIsVisible}
        location={""}
        textIsRed={menuIsWhite ? false : true}
      >
        <SEO
          title="Loony Communication - art - design"
          description={`Bienvenue dans l’univers communicant et créatif des Loony.\n
Nous vous accompagnons dans votre communication visuelle, stratégique, événementielle et digitale à Bordeaux, Lille et partout en France.`}
        />
        {!menuIsVisible && (
          <Logo src={LogoLoony} alt="logo loony" isMobile={isMobile} />
        )}
        {!isMobile && <MousePicto isMobile={isMobile} />}

        <Splash ref={splashRef} isMobile={isMobile} />

        <SectionWrapper isMobile={isMobile} ref={skillsWrapperRef}>
          <EyesBox isMobile={isMobile} ref={eyesBoxRef}>
            <img
              id="eyesZoom"
              src={data.eyes.childImageSharp.fluid.src}
              style={{
                ...EyesStyle,
                width: isMobile ? "100vw" : "40vw",
              }}
              ref={eyesRef}
              alt="Loony images yeux"
            />
          </EyesBox>
          {isMobile && (
            <div
              style={{
                background: "linear-gradient(#fff, #0B0B0B)",
                height: "100vh",
                width: "100vw",
              }}
            ></div>
          )}
          {!isMobile && (
            <SkillsScreenWrapper
              id="skillsId"
              ref={skillsRef}
              isMobile={isMobile}
              className="noCursor"
            >
              <Skills
                isMobile={isMobile}
                infosWordings={{
                  branding: data.config.branding,
                  event: data.config.event,
                  digital: data.config.digital,
                  studioCreatif: data.config.studioCreatif,
                }}
              />
            </SkillsScreenWrapper>
          )}
        </SectionWrapper>

        {isMobile && (
          <SkillsScreenWrapper
            id="skillsId"
            ref={skillsRef}
            isMobile={isMobile}
          >
            <Skills
              isMobile={isMobile}
              infosWordings={{
                branding: data.config.branding,
                event: data.config.event,
                digital: data.config.digital,
                studioCreatif: data.config.studioCreatif,
              }}
            />
          </SkillsScreenWrapper>
        )}

        <Baseline
          isMobile={isMobile}
          ref={baselineRef}
          wording={{
            baseline: data.config.baseline.baseline,
            subBaseline: data.config.subBaseline.subBaseline,
          }}
        />
        <LowerZone
          isMobile={isMobile}
          setMenuIsWhite={setMenuIsWhite}
          displayProjectsByPosition={data.config.displayProjectsByPosition}
        />

        <div
          ref={contactTriggerRef}
          style={{
            width: "100%",
            height: "1px",
            backgroundColor: `${Theme.colors.red}`,
          }}
        />
      </Layout>
    </div>
  );
};

export default IndexPage;
