import styled from "styled-components"
import { Theme } from "./Theme"
import maskCircle from "@Images/pictos/circle.png"

export const ProjectsContactWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  margin-top: ${props => props.isMobile ? 0 : "-100vh"};
  z-index: 5000;
  position: relative;
  z-index: 5000;
  clip-path: ${props => props.isMobile ? "unset" : "polygon(0 0, 0 0, -15% 100%, 0 100%)"};
  transition: .5s cubic-bezier(.12,.97,0,.97);
`

export const ProjectsWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4000;
  padding: 1rem 8rem;
  overflow-x: scroll;
  overflow-y: hidden;
  background-color: ${Theme.colors.red};
  mask-image: ${props => props.isMobile ? "unset" : `url(${maskCircle})`};
  mask-repeat: no-repeat;
  mask-position: 50% 50%;
  mask-size: 150%;
  transition: .5s cubic-bezier(.12,.97,0,.97);

  &::-webkit-scrollbar-track
  {
    background-color: ${Theme.colors.red};
  }

  &::-webkit-scrollbar{
    width: 10px;
    background-color: ${Theme.colors.red};
  }

&::-webkit-scrollbar-thumb{
  background-color: ${Theme.colors.darkRed};	
  border-radius: 5px;
}

  &.visible{
    z-index: 2000;
    transition: opacity .25s ease-out;
    opacity: 1 !important;
    mask-image: unset !important;
  }
`

export const Slider = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  // gap: 2rem;
`