import styled from 'styled-components'

export const SplashScreenWrapper = styled.div`
  width: 100vw;
  max-width: 100vw;
  overflow: hidden;
  position: relative;
  z-index: 1000;
  padding-top: 5vw;
  transition: all .6s cubic-bezier(.14,.98,.49,1.02);
  transform: scale(1.1);
  transform-origin: top;

  @media (max-width: 1024px){
    transform: none;
  }
`

export const Halo = styled.div`
  width: 80%;
  height: 80vw;
  background: radial-gradient(circle at 50% 35%, #ffffff 35%, transparent 50%);
  position: absolute;
  top: -5vw;
  left: 10%;
  z-index: 0;
  display: block;
`

export const DeskTopView = styled.div`
`

export const MobileView = styled.div`
  position: relative;
`

/***************************************************************************/
/********************************* DESKTOP *********************************/
/***************************************************************************/


// export const Flore = styled.img`
//   width: 30%;
//   position: absolute;
//   top: 5vw;
//   left: 20.2%;
//   z-index: 200;
// `

// export const Sam = styled.img`
//   width: 37%;
//   position: absolute;
//   top: 5vw;
//   right: 20%;
//   z-index: 100;
// `

export const Flore = {
  width: "30%",
  position: "relative",
  left: "20.2%",
  zIndex: "200",
  display: "inline-block"
}

export const Sam = {
  width: "35.5%",
  position: "relative",
  left: "15.2%",
  zIndex: "100",
  display: "inline-block"
}

export const Eyes = {
  width: "30vw",
  zIndex: "500",
  position: "sticky",
  left: "50%",
  top: "calc(50% - 7.5vw - 5px)",
  transform: "translate(-50%, -20%)",
  willChange: "transform",
  transformStyle: "preserve-3d",
  transition: "transform 1s cubic-bezier(.12,.74,0,1)"
}

export const Lasers = {
  width: "30%",
  position: "absolute",
  top: "-2.6vw",
  right: "11.6%",
  zIndex: "300",
  opacity: "0",
}

export const GameOver = {
  width: "3.9%",
  position: "absolute",
  top: "9.9vw",
  left: " 36.6%",
  zIndex: "300",
  opacity: "0",
}

export const BangContainer = styled.div`
  opacity: 0;
`

export const BangOne = {
  width: "5%",
  position: "absolute",
  top: "9.6vw",
  left: "39.1%",
  zIndex: "300",
  opacity: "1",
}

export const BangTwo = {
  width: "5%",
  position: "absolute",
  top: "9.4vw",
  left: "36.8%",
  zIndex: "400",
  opacity: "1",
}

export const BlueBrushOne = {
  width: '30%',
  position: 'absolute',
  top: "20vw",
  left: "14%",
  zIndex: "10"
}

export const BlueBrushTwo = {
  width: "30%",
  position: "absolute",
  top: "24vw",
  left: "18%",
  zIndex: "20"
}

export const BlueBuildings = {
  width: "10%",
  position: "absolute",
  top: "28vw",
  left: "40%",
  zIndex: "40"
}

export const BlueOwl = {
  width: "10%",
  position: "absolute",
  top: "26vw",
  right: "23.5%",
  zIndex: "50"
}

export const BluePalmOne = {
  width: '8%',
  position: 'absolute',
  top: '5vw',
  left: '40%',
  zIndex: '20'
}

export const BluePalmTwo = {
  width: '6%',
  position: 'absolute',
  top: '12vw',
  left: '45%',
  zIndex: '20'
}

export const RedBrushOne = {
  width: "20%",
  position: "absolute",
  top: "24.5vw",
  right: "22%",
  zIndex: "10"
}

export const RedFlowerOne = {
  width: "10%",
  position: "absolute",
  top: "23vw",
  right: "44%",
  zIndex: "50"
}

export const RedFlowerTwo = {
  width: "7%",
  position: "absolute",
  top: "25vw",
  left: "39%",
  zIndex: "50"
}

export const RedPalmLeaf = {
  width: "40%",
  position: "absolute",
  top: "0",
  left: "30%",
  zIndex: "10"
}

export const RedPaperOne = {
  width: "8%",
  position: "absolute",
  top: "30vw",
  left: "42%",
  zIndex: "30"
}

export const RedPaperTwo = {
  width: "8%",
  position: "absolute",
  top: "24vw",
  left: "40%",
  zIndex: "10"
}

/**************************************************************************/
/********************************* MOBILE *********************************/
/**************************************************************************/

export const FloreBox = styled.div`
  width: 100vw;
  position: relative;
  padding-top: 15%;
  opacity: 1;
  overflow: hidden;
  animation: infinite floreDisapear 6s linear;

  @keyframes floreDisapear{
    0%, 40%, 100%{
      opacity: 1;
    }
    50%, 90%{
      opacity: 0;
    }
  }
`

// export const FloreMobile = styled.img`
//   width: 130%;
//   position: relative;
//   z-index: 50;
//   left: -17.5%;
// `

export const FloreMobile = {
  width: "130%",
  position: "relative",
  zIndex: "50",
  left: "-17.5%",
}

export const RedPalmLeafMobile = {
  width: "120%",
  position: "absolute",
  right: "-35%",
  top: "8vw",
  zIndex: "10",
}

export const RedBrushOneMobile = {
  width: "70%",
  position: "absolute",
  right: "-10%",
  top: "140vw",
  zIndex: "30",
  transform: "rotate(90deg)",
}

export const RedPaperOneMobile = {
  width: "30%",
  position: "absolute",
  right: "0",
  top: "80vw",
  zIndex: "20",
  transform: "rotate(-90deg)",
}

export const RedFlowerOneMobile = {
  width: "50%",
  position: "absolute",
  left: "0",
  top: "90vw",
  zIndex: "20",
}

export const SamBox = styled.div`
  width: 100vw;
  position: absolute;
  top:0;
  left:0;
  padding-top: 15%;
  overflow: hidden;
  opacity: 0;
  animation: infinite SamDisapear 6s linear;

  @keyframes SamDisapear{
    0%, 40%, 100%{
      opacity: 0;
    }
    50%, 90%{
      opacity: 1;
    }
  }
`

export const SamMobile = {
  width: "140%",
  position: "relative",
  left: "-10%",
  zIndex: "50",
}

export const BluePalmOneMobile = {
  width: "20%",
  position: "absolute",
  top: "25vw",
  left: "12.5%",
}

export const BluePalmTwoMobile = {
  width: "40%",
  position: "absolute",
  top: "5vw",
  left: "-10%",
}

export const BlueBrushOneMobile = {
  width: "80%",
  position: "absolute",
  top: "100vw",
  left: "7%",
  zIndex: "20",
}

export const BlueBrushTwoMobile = {
  width: "90%",
  position: "absolute",
  top: "90vw",
  left: "-5%",
  zIndex: "10",
  transform: "rotate(180deg)",
}

export const BlueBuildingsMobile = {
  width: "40%",
  position: "absolute",
  top: "75vw",
  right: "-10%",
  zIndex: "30",
}

export const BlueOwlMobile = {
  width: "35%",
  position: "absolute",
  top: "150vw",
  right: "3%",
  zIndex: "10",
}

export const LostBackground = styled.div`
  width: 100%;
  height: 100vh;
`