import styled from "styled-components"
import mask from "@Images/skillsScreen/mask.png"
import { Theme } from "@Styles/Theme"
import maskCircle from "@Images/pictos/circle.png"

export const PageContainer = styled.div`
`

export const Logo = styled.img`
  width: ${props => props.isMobile ? "40vw" : `15vw`};
  height: auto;
  position: fixed;
  top: 25px;
  left: 25px;
  z-index: 10000;
`

export const SectionWrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  position: static;
  z-index: 2000;
  top: 0;
  transform: translateY(-35vh);
  margin-bottom: -35vh;
`

export const EyesStyle = {
  // width: "40vw",
  willChange: "transform",
  transformStyle: "preserve-3d",
}

export const EyesBox = styled.div`
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: ${props => props.isMobile ? "sticky" : `absolute`};
  top: ${props => props.isMobile ? "35vh" : `0`};
  left: 0;
  z-index: 3000;
  overflow: hidden;
`

export const SkillsScreenWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #0B0B0B;
  position: ${props => props.isMobile ? "relative" : `absolute`};;
  top: 0;
  left: 0;
  z-index: 9000;
  mask-image: ${props => props.isMobile ? "unset" : `url(${mask})`};
  mask-repeat: no-repeat;
  mask-position: 50% 50%;
  mask-size: ${props => props.isMobile ? "100vw" : `40vw`};
  opacity: ${props => props.isMobile ? "1" : `0`};
  overflow: visible;
  transform-origin: top;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  transition: .5 linear;

  &.noCursor *{
    cursor: default !important;
  }

  &::-webkit-scrollbar,
  &::-webkit-scrollbar {
    display: none;
  }

  &.visible{
    overflow: visible;
    z-index: 9000;
    transition: opacity .25s ease-out;
    opacity: 1 !important;
    mask-image: unset !important;
  }
`

export const ProjectsWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  top: -100vh;
  z-index: 2000;
  overflow-x: scroll;
  overflow-y: hidden;
  background-color: ${Theme.colors.red};
  mask-image: ${props => props.isMobile ? "unset" : `url(${maskCircle})`};
  mask-repeat: no-repeat;
  mask-position: 50% 50%;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  mask-size: 150%;
  transition: .5s cubic-bezier(.12,.97,0,.97);
  margin: 0;
  padding: 1rem 8rem;

  &::-webkit-scrollbar-track
  {
    background-color: ${Theme.colors.red};
  }

  &::-webkit-scrollbar{
    width: 10px;
    background-color: ${Theme.colors.red};
  }

&::-webkit-scrollbar-thumb{
  background-color: ${Theme.colors.darkRed};	
  border-radius: 5px;
}

  &.visible{
    z-index: 2000;
    transition: opacity .25s ease-out;
    opacity: 1 !important;
    mask-image: unset !important;
  }
`

export const Slider = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  // gap: 2rem;
`

export const ProjectsContactWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  margin-top: ${props => props.isMobile ? 0 : "-100vh"};
  z-index: 5000;
  position: relative;
  z-index: 5000;
  clip-path:   ${props => props.isMobile ? null : "polygon(0 0, 0 0, -15% 100%, 0 100%)"};
  transition: .5s cubic-bezier(.12,.97,0,.97);
`